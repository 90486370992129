import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`flex flex-col w-full relative order-1 md:order-2`}
    .bottomline{ ${tw`w-full h-2 absolute bottom-0 left-0`} }
`

export const BlockContent = styled.div`
  ${tw`flex flex-col items-start justify-start bg-cover bg-no-repeat pt-10 text-white`}
  background-image: ${({ bgasset }) => `url(${bgasset})`};

  .text-wrapper{
    ${tw`max-w-screen-xl mx-auto px-4 sm:px-8 xl:px-0 box-border flex flex-col sm:flex-row`}

    .copyblock{
      ${tw`w-full sm:w-1/2 text-left sm:text-center sm:pr-10`}
    }
    .notepad-quotes{
      ${tw`w-full sm:w-1/2 relative flex`}
      font-size: 0;

      small{
        ${tw`block`}
      }

      .notepad-image{
        ${tw`w-full`}
      }

      .rotation{
        ${tw`absolute top-0 w-full h-full box-border`}
        position: absolute;
        padding-top: 20%;
        padding-right: 28%;
        padding-left: 19%;
        transform: rotateZ(5.5deg);

        @media (max-width: 1200px){
          padding-top: 20%;
          padding-right: 29%;
          padding-left: 20%;
        }
        @media (max-width: 639px){
          padding-top: 20%;
          padding-right: 25%;
          padding-left: 16%;
        }

        button{
            ${tw`bg-transparent border-0`}

            &.arrow-back{
              ${tw`absolute mr-0 sm:mr-10`}
              left: 13%;
              top: 40%;   
              @media (max-width: 1200px){
                left: 10%;
              }
              @media (max-width: 639px){
                left: 6.5%;
              }
              @media (max-width: 1023px){
                top: 30%;   
                transform: scale(0.6);
              }

            }

            &.arrow-forth{
                ${tw`absolute ml-0 sm:ml-10`}
                right: 22%;
                top: 40.3%;
                @media (max-width: 1200px){
                  right: 20%;
                }
                @media (max-width: 639px){
                  right: 16%;
                }
                @media (max-width: 1023px){
                  top: 30%;   
                  transform: scale(0.6);
                }
            }
        }

        .mask{
        ${tw`w-full h-full justify-between box-border    flex flex-nowrap   overflow-hidden`}

          .slide{
            ${tw`flex flex-col w-full h-full text-center text-darkgray`}
            flex: 0 0 auto;

            .quote{
              ${tw`
                italic
                overflow-auto h-full sm:h-auto
                text-base leading-tight  lg:leading-normal lg:text-2xl font-bold
              `}
              @media (min-width: 450px){
                font-size: 1.25rem;
              }
              @media (min-width: 1024px){
                font-size: 1.5rem;
              }
            }
            .signature{
              width: 80%;
              padding-bottom: 3vh;

              small{
                ${tw`text-sm sm:text-base italic leading-tight  lg:leading-normal  lg:text-2xl font-bold text-left text-lightorange`}
              }
            }
          }
        }
      }
    }
  }
`