import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`w-full flex flex-col relative order-2 md:order-1`}
    background-color: ${({ bgColorClass }) => `${bgColorClass}`};
    .cta-container {
        ${tw`w-full flex justify-center pb-10 md:pb-16`}
    }
    .cta.white{
        background: #fff;
        text-transform: uppercase;
        color: #C53427;
        font-size: 18px;
        border-radius: 9999px;
        border: 7px solid #F36F34;
        text-decoration: none;
        transition-property: all;
        transition-duration: 150ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        ${tw`md:px-8 px-4 font-bold text-lg`}
    }
    .cta.white:hover{
        color: #fff;
        background: #F36F34;
        border: 7px solid #fff;
    }

    .cta-headline {
        ${tw`text-center text-xl md:text-2xl mb-3`}
    }
`

export const TextBlock = styled.div`
    ${tw`max-w-screen-xl py-2 md:py-2 lg:py-8 px-8 xl:px-0 mx-auto`}

    h2{
        ${tw`text-left md:text-center text-darkgray my-0 mt-8 md:mt-0`}
        
    }

    p.main-message{
        ${tw`text-left md:text-center mx-auto`}
        max-width: 820px;
    }

    .columns {
        ${tw`grid grid-cols-1 md:grid-cols-2 md:gap-4 mt-5 md:mt-16`}
    }

    .column p.title {
        ${tw`text-orange text-center md:text-left text-2xl`}
        margin: 0;
    }

    .column ul.sols {
        list-style: none;
        padding:0;
    }

    .column ul.sols li::before {
        ${tw`pr-3 md:pr-5`}
        content: "•";
        color: #C53427;
    }

    .highlight {
        ${tw`font-bold`}
        color: #C53427;
    }

    

    .only-mobile {
        ${tw`block md:hidden`}
    }

    .tile-grid{
        ${tw`grid  mx-auto mt-12`}
        grid-template-columns: 1fr auto;
        max-width: 880px;

        .tile{

            ${tw`mb-8`}

            h3{
                ${tw`text-orange font-normal text-2xl m-0 mb-2`}
            }

            ul{
                ${tw`list-none p-0 pl-3`}

                li{
                    &:before{
                        ${tw`absolute text-orange inline -ml-3`}
                        content: "•"
                    }
                }
            }
        }

        @media (max-width: 840px){
            max-width: 480px;

            ${tw`grid-cols-1`}

            h3{ ${tw`text-center`} }
            ul{
                width: 100%;
                max-width: 20rem;
                margin: 0 auto;
                display: block;
            }
        }
    }
`