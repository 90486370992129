import React, { useRef, useEffect } from "react";
import { Wrapper, BlockContent} from "./QuotesBlock.styled"
import BottomLine from "../../BottomLine/BottomLine"
import 'twin.macro'
import gsap from 'gsap';

import ArrowBack from "../../../images/arrow-back.svg"
import ArrowForth from "../../../images/arrow-forth.svg"




const QuotesBlock = ({assets})=> {  
    
    let slideIndex = 0;
    const mask = useRef();
    const back = useRef();
    const forth = useRef();
    const $ = gsap.utils.selector(mask);
    let timeout;

    useEffect(() => {
        checkArrowsState();

        window.addEventListener('resize', checkArrowsStateDelay);

        return () => {
            window.removeEventListener('resize', checkArrowsStateDelay);
        }
    }); 

    const checkArrowsStateDelay = ()=> {
        clearTimeout(timeout);
        timeout = setTimeout(()=>{
            checkArrowsState()
        }, 100)
    }

    const goBack = (e)=>{
        if(slideIndex > 0){
            slideIndex--;
            requestSlide()
        }
    }

    const goForth = (e)=>{
        if( slideIndex < (assets.quoteslist.length) - Math.round(mask.current.offsetWidth / $('.slide')[0].offsetWidth)){
            slideIndex++;
            requestSlide()
        }
    }

    const requestSlide = ()=> {
        console.log(slideIndex)
        gsap.to($('.slide'), {duration:0.5, ease:"power3.inOut", x:`-${100 * slideIndex}%`})

        checkArrowsState();
    }

    const checkArrowsState = ()=> {
        (slideIndex === 0)
            ? gsap.set(back.current, {opacity:0.25, 'pointer-events': 'none'})
            : gsap.set(back.current, {opacity:1, 'pointer-events': 'all'});
        
        (slideIndex === (assets.quoteslist.length - Math.round(mask.current.offsetWidth / $('.slide')[0].offsetWidth)))
            ? gsap.set(forth.current, {opacity:0.25, 'pointer-events': 'none'})
            : gsap.set(forth.current, {opacity:1, 'pointer-events': 'all'});
    }
    
    return(
        <Wrapper>

            <BlockContent bgasset={assets.bgTexture.localFile.publicURL}>
                <div className="text-wrapper">
                    <div className="copyblock">
                        <h2>{assets.title}</h2>
                        <p>{assets.mainCopy}</p>
                    </div>
                    <div className="notepad-quotes">
                        <img className="notepad-image" src={assets.notepadImage.localFile.publicURL} alt=""/>
                        <div className="rotation">

                            <button ref={back} onClick={goBack} className="arrow-back">
                                <img src={ArrowBack} alt=""/>
                            </button>

                            <div ref={mask} className="mask">
                                {assets.quoteslist.map( (item, index)=> (
                                    <div className="slide" id={`slide${index}`} key={index}>
                                        <p className="quote">"{item.mainContent}"</p>
                                        <div className="signature">
                                            <small>{item.personTitle}</small>
                                            <small>{item.organization}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <button ref={forth} onClick={goForth} className="arrow-forth">
                                <img src={ArrowForth} alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
                <BottomLine/>
            </BlockContent>

        </Wrapper>
    )
}

export default QuotesBlock