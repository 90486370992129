import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`relative`}
    .bottomline{ ${tw`w-full h-2 absolute bottom-0 left-0`} }
`

export const BlockContent = styled.div`
  ${tw`hidden sm:flex flex-col items-start justify-start bg-cover bg-no-repeat py-10 text-darkgray`}
  background-image: ${({ bgasset }) => `url(${bgasset})`};
  background-position-x: 5rem;

  @media (max-width: 1140px){
    background-position-x: 0rem;
  }

  .text-wrapper{
    ${tw`max-w-screen-xl mx-auto px-4 sm:px-8 xl:px-0 box-border`}

    h2{
      ${tw`mt-3 text-5xl`}
    }
    h3{
      ${tw`text-5xl font-thin text-center`}
      margin-top: 10vw;
    }
    p{
      ${tw`text-center`}
      max-width: 820px;
    }
  }
  

  .chooseLogo{
      ${tw`block`}
  }
`

export const BlockContentMobile = styled.div`
  ${tw`flex sm:hidden flex-col items-center justify-center text-darkgray bg-cover bg-no-repeat`}
  background-image: ${({ bgassetmob }) => `url(${bgassetmob})`};

  .text-wrapper{
    ${tw`mx-auto px-4 sm:px-8 xl:px-0 box-border`}

    .chooseLogo{
      ${tw`block`}
      max-width: 11rem;
      margin-top: 35vw
    } 
    h3{
      ${tw`text-2xl font-thin text-left mt-4 mb-2 pr-12`}
    }
    p{
      ${tw`pr-12 mb-10`}
    }

  }
`