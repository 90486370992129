import React, {useEffect} from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"

// Components
import TopHero from "../components/Work/TopHero/TopHero"
import { Container } from "../components/Work/Container/Container.styled"
import QuotesBlock from "../components/Work/QuotesBlock/QuotesBlock"
import InfoBlock from "../components/Work/InfoBlock/InfoBlock"

// Hooks
import { useWorkQuery } from "../hooks/useWorkQueries"
import ClientsBlock from "../components/Work/ClientsBlock/ClientsBlock"


const Works = ({ children }) => {

  const data = useWorkQuery();

  console.log(data)

  useEffect(() => {
    // Mount

    // Unmount
    return () => {

    } 
  }, []); 

  return(
    <Layout>
      <Seo title="Work" />
      <TopHero assets={data.wpPage.ACF_WorkPage.workHero} />
      <Container>
        <QuotesBlock assets={data.wpPage.ACF_WorkPage.quotes} />
        <InfoBlock info={data.wpPage.ACF_WorkPage.solutions} bgColor='#EBEAEA'/>
      </Container>
    </Layout>
  )

}

export default Works
