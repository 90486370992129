import { useStaticQuery, graphql } from "gatsby"

export const useWorkQuery = ()=> {

    return useStaticQuery(graphql`
    
        query WorkQuery {

            wpPage(title: {eq: "WORK"}) {
                ACF_WorkPage {
                    
                    solutions {
                        mainCopy
                        ctaHeadline
                        ctaLabel
                        ctaUrl
                    }

                    clients {
                        title
                        mainCopy
                        logos {
                            image {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                    quotes {
                        mainCopy
                        title
                        quoteslist {
                            mainContent
                            organization
                            personTitle
                        }
                        bgTexture {
                            localFile {
                                publicURL
                            }
                        }
                        notepadImage {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    workHero {
                        animatedTitle
                        subtitle
                        mainCopy
                        desktopImage {
                            localFile {
                                publicURL
                            }
                        }
                        mobileImage {
                            localFile {
                                publicURL
                            }
                        }
                    }
                }
            }
            
        } 
    `)
}